import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import SEO from 'components/SEO';
import Section from 'components/Section';
import Link from 'components/Link';
import useSiteMetadata from 'hooks/useSiteMetadata';

export default function FAQPage() {
  const { t } = useTranslation();
  const site = useSiteMetadata();

  const faqs = [
    {
      question: t('faq:q1', { site: site.title }),
      answer: t('faq:a1', { site: site.title }),
    },
    {
      question: t('faq:q2'),
      answer: (
        <Trans
          i18nKey="faq:a2"
          values={{ site: site.title, page: t('common:supported_sites') }}
          components={[
            <Link
              href="/sites"
              className="font-medium text-gray-900 underline"
            />,
          ]}
        />
      ),
    },
    {
      question: t('faq:q3'),
      answer: t('faq:a3'),
    },
    {
      question: t('faq:q4'),
      answer: t('faq:a4', { site: site.title }),
    },
    {
      question: t('faq:q5', { site: site.title }),
      answer: t('faq:a5', { site: site.title }),
    },
    {
      question: t('faq:q6'),
      answer: t('faq:a6'),
    },
    {
      question: t('faq:q7'),
      answer: t('faq:a7', { site: site.title }),
    },
    {
      question: t('faq:q8'),
      answer: t('faq:a8'),
    },
    {
      question: t('faq:q9'),
      answer: (
        <Trans
          i18nKey="faq:a9"
          values={{ site: site.title }}
          components={[
            <Link
              href="/terms"
              className="font-medium text-gray-900 underline"
            />,
          ]}
        />
      ),
    },
    {
      question: t('faq:q10'),
      answer: t('faq:a10'),
    },
  ];

  const title = t('faq:meta.title');

  return (
    <>
      <SEO
        title={`${title} - ${site.title}`}
        description={t('faq:meta.desc')}
        keywords={title}
      />

      <Section>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl lg:mx-auto lg:text-center">
            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {title}
            </h1>
            <p className="mt-4 text-gray-500">{t('faq:find_answers')}</p>
          </div>

          <div className="mt-20">
            <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
              {faqs.map((faq, index) => (
                <div key={index}>
                  <dt className="font-semibold text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-3 text-gray-500">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-20 max-w-2xl lg:mx-auto lg:text-center">
            <p className="text-gray-500">{t('faq:cant_find')}</p>
            <p className="mt-4 text-gray-500">
              <Trans
                i18nKey="faq:contact_support"
                components={[
                  <Link
                    href="/contact"
                    className="font-medium text-blue-600 hover:text-blue-500"
                  />,
                ]}
              />
            </p>
          </div>
        </div>
      </Section>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "faq", "videoproc"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
